<template>
  <div class="text-left text-md-start">
    <div class="secondary--text mb-8 title">
      {{ prefix }} {{ currentUser.firstName }}
      {{ currentUser.lastName }}
    </div>
    <v-row>
      <v-col cols="12" md="4" class="mb-5">
        <InfoItem icon="mdi-phone" label="Teléfono" :val="currentUser.phone" />
      </v-col>
      <v-col cols="12" md="4" class="mb-5">
        <InfoItem
          icon="mdi-email"
          label="Correo electrónico"
          :val="currentUser.email"
        />
      </v-col>
      <v-col cols="12" md="4" class="mb-5">
        <InfoItem
          icon="mdi-card-account-details-star"
          label="Exequatur"
          :val="currentUser.doctor.exequatur"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="4" class="mb-5">
        <InfoItem
          icon="mdi-gender-male-female"
          label="Sexo biológico"
          :chipVals="
            currentUser.doctor.gender
              ? [
                  {
                    text:
                      currentUser.doctor.gender === 'F'
                        ? 'Femenino'
                        : 'Masculino',
                    color:
                      currentUser.doctor.gender === 'F' ? 'pink' : 'primary',
                  },
                ]
              : []
          "
        />
      </v-col>
      <v-col cols="12" md="8" class="mb-5">
        <InfoItem
          icon="mdi-stethoscope"
          label="Especialidades"
          :chipVals="
            specialties.map((specialty) => {
              return {
                text: specialty.description,
                color: 'dark_primary',
              };
            })
          "
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="mb-5">
        <InfoItem
          icon="mdi-hospital-box"
          label="Aseguradoras aceptadas"
          :chipVals="
            ars.map((ars) => {
              return {
                text: ars.description,
                color: 'dark_primary',
              };
            })
          "
        />
      </v-col>
    </v-row>
    <v-row class="mt-16">
      <v-col cols="12" class="d-flex justify-sm-end justify-center">
        <v-btn class="mr-5" depressed small @click="handleClickUpdate">
          <v-icon small class="mr-1">mdi-square-edit-outline</v-icon>
          modificar
        </v-btn>
        <v-btn small depressed @click="showChangePassword = true">
          <v-icon small class="mr-1">mdi-lock</v-icon>
          <span class="d-none d-sm-inline">cambiar contraseña</span>
          <span class="d-inline d-sm-none">contraseña</span>
        </v-btn>
      </v-col>
    </v-row>

    <v-dialog
      width="800"
      v-model="showForm"
      @keydown.esc="showForm = false"
      persistent
    >
      <v-card class="pa-sm-10 pa-5">
        <div class="text-right">
          <v-icon @click="showForm = false" style="cursor: pointer">
            mdi-close
          </v-icon>
        </div>
        <DoctorForm ref="DoctorForm" @updated="handleUpdated" />
      </v-card>
    </v-dialog>

    <v-dialog
      width="600"
      v-model="showChangePassword"
      @keydown.esc="showChangePassword = false"
      persistent
    >
      <v-card class="pa-sm-10 pa-5">
        <div class="text-right">
          <v-icon @click="showChangePassword = false" style="cursor: pointer">
            mdi-close
          </v-icon>
        </div>
        <ChangePassword @updated="showChangePassword = false" />
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import DoctorForm from "@/components/Profile/UpdateProfile/DoctorForm";
import ChangePassword from "@/components/Profile/ChangePassword";
import InfoItem from "../InfoItem.vue";
import axios from "axios";

export default {
  name: "Doctor",
  components: {
    InfoItem,
    DoctorForm,
    ChangePassword,
  },
  data() {
    return {
      showForm: false,
      showChangePassword: false,
    };
  },
  computed: {
    ...mapGetters(["currentUser"]),
    prefix() {
      if (this.currentUser.doctor.gender === "M") {
        return "Dr.";
      } else if (this.currentUser.doctor.gender === "F") {
        return "Dra.";
      } else {
        return "";
      }
    },
    specialties() {
      return this.currentUser?.doctor?.specialties || [];
    },
    ars() {
      return this.currentUser?.doctor?.ars || [];
    },
  },
  methods: {
    ...mapMutations(["setAlert", "setCurrentUser"]),
    handleClickUpdate() {
      this.showForm = true;
      this.$nextTick(() => {
        this.$refs.DoctorForm.item = {
          id: this.currentUser.doctor.id,
          gender: this.currentUser.doctor.gender,
          specialties: this.currentUser.doctor.specialties,
          ars: this.currentUser.doctor.ars,
          subscription: this.currentUser.doctor.subscription,
          exequatur: this.currentUser.doctor.exequatur,
          user: this.currentUser,
        };
      });
    },
    async handleUpdated() {
      this.showForm = false;
      const res = await axios.get("/api/currentUser");
      const { user } = res.data;
      if(user) {
        this.setCurrentUser(user);
      }
    },
  },
};
</script>
