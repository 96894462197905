<template>
  <div>
    <v-form @submit.prevent="changePassword()">
      <v-container>
        <Title :title="'Cambiar contraseña'" class="mb-8" />
        <v-row>
          <v-col cols="12">
            <v-text-field
              v-model="password"
              label="Contraseña actual"
              prepend-icon="mdi-lock"
              :append-icon="showCurrentPass ? 'mdi-eye' : 'mdi-eye-off'"
              :type="showCurrentPass ? 'text' : 'password'"
              @click:append="showCurrentPass = !showCurrentPass"
              outlined
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-text-field
              v-model="newPassword"
              :error="!validNewPassword && this.newPassword !== ''"
              label="Contraseña nueva"
              prepend-icon="mdi-lock-plus"
              :append-icon="showNewPass ? 'mdi-eye' : 'mdi-eye-off'"
              :type="showNewPass ? 'text' : 'password'"
              @click:append="showNewPass = !showNewPass"
              :hint="
                validNewPassword
                  ? ''
                  : 'Al menos: 8 caráteres | 1 número | 1 mayúscula | 1 minúscula'
              "
              :persistent-hint="!validNewPassword && this.newPassword !== ''"
              outlined
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-text-field
              v-model="newPasswordRepited"
              :error="!confirmedPassword && this.newPasswordRepited !== ''"
              label="Confirmar contraseña nueva"
              prepend-icon="mdi-lock-reset"
              :append-icon="showNewPassRepited ? 'mdi-eye' : 'mdi-eye-off'"
              :type="showNewPassRepited ? 'text' : 'password'"
              @click:append="showNewPassRepited = !showNewPassRepited"
              :hint="confirmedPassword ? '' : 'Contraseñas no coinciden'"
              :persistent-hint="
                !confirmedPassword && this.newPasswordRepited !== ''
              "
              outlined
            ></v-text-field>
          </v-col>
        </v-row>
      </v-container>
      <v-card-actions class="text--right">
        <v-btn
          small
          color="primary"
          class="pa-5"
          type="submit"
          :disabled="!validFields"
          :loading="loading"
        >
          <v-icon class="mr-2">
            {{ validFields ? "mdi-lock-check" : "mdi-lock-remove" }}
          </v-icon>
          Actualizar
        </v-btn>
      </v-card-actions>
    </v-form>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import { passwordValidation } from "../../helpers/common";
import axios from "axios";
import Title from "../Title.vue";

export default {
  name: "ChangePassword",
  components: {
    Title
  },
  data() {
    return {
      password: "",
      newPassword: "",
      newPasswordRepited: "",
      loading: false,
      validation: true,
      showCurrentPass: false,
      showNewPass: false,
      showNewPassRepited: false,
    };
  },
  computed: {
    ...mapGetters(["currentUser"]),
    validNewPassword() {
      return passwordValidation(this.newPassword);
    },
    confirmedPassword() {
      return this.newPassword === this.newPasswordRepited;
    },
    validFields() {
      return (
        this.password !== "" && this.validNewPassword && this.confirmedPassword
      );
    },
  },
  methods: {
    ...mapMutations(["setAlert"]),
    resetForm() {
      this.password = "";
      this.newPassword = "";
      this.newPasswordRepited = "";
    },
    async changePassword() {
      this.loading = true;
      try {
        const form = { password: this.password, newPassword: this.newPassword };
        await axios.put(`/api/password/${this.currentUser.id}`, form);
        this.resetForm();
        this.$emit("updated");
        this.setAlert({
          show: true,
          color: "success",
          icon: "mdi-check-circle",
          text: "Contraseña actualizada correctamente",
        });
      } catch (error) {
        console.log(error);
        const errorAlert = {
          show: true,
          color: "error",
          icon: "mdi-alert-remove",
          text: error.response.data.message,
        };
        this.setAlert(errorAlert);
      }
      this.loading = false;
    },
  },
};
</script>
