var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text-left text-md-start"},[_c('div',{staticClass:"secondary--text mb-8 title"},[_vm._v(" "+_vm._s(_vm.prefix)+" "+_vm._s(_vm.currentUser.firstName)+" "+_vm._s(_vm.currentUser.lastName)+" ")]),_c('v-row',[_c('v-col',{staticClass:"mb-5",attrs:{"cols":"12","md":"4"}},[_c('InfoItem',{attrs:{"icon":"mdi-phone","label":"Teléfono","val":_vm.currentUser.phone}})],1),_c('v-col',{staticClass:"mb-5",attrs:{"cols":"12","md":"4"}},[_c('InfoItem',{attrs:{"icon":"mdi-email","label":"Correo electrónico","val":_vm.currentUser.email}})],1),_c('v-col',{staticClass:"mb-5",attrs:{"cols":"12","md":"4"}},[_c('InfoItem',{attrs:{"icon":"mdi-card-account-details-star","label":"Exequatur","val":_vm.currentUser.doctor.exequatur}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"mb-5",attrs:{"cols":"12","md":"4"}},[_c('InfoItem',{attrs:{"icon":"mdi-gender-male-female","label":"Sexo biológico","chipVals":_vm.currentUser.doctor.gender
            ? [
                {
                  text:
                    _vm.currentUser.doctor.gender === 'F'
                      ? 'Femenino'
                      : 'Masculino',
                  color:
                    _vm.currentUser.doctor.gender === 'F' ? 'pink' : 'primary',
                },
              ]
            : []}})],1),_c('v-col',{staticClass:"mb-5",attrs:{"cols":"12","md":"8"}},[_c('InfoItem',{attrs:{"icon":"mdi-stethoscope","label":"Especialidades","chipVals":_vm.specialties.map((specialty) => {
            return {
              text: specialty.description,
              color: 'dark_primary',
            };
          })}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"mb-5",attrs:{"cols":"12"}},[_c('InfoItem',{attrs:{"icon":"mdi-hospital-box","label":"Aseguradoras aceptadas","chipVals":_vm.ars.map((ars) => {
            return {
              text: ars.description,
              color: 'dark_primary',
            };
          })}})],1)],1),_c('v-row',{staticClass:"mt-16"},[_c('v-col',{staticClass:"d-flex justify-sm-end justify-center",attrs:{"cols":"12"}},[_c('v-btn',{staticClass:"mr-5",attrs:{"depressed":"","small":""},on:{"click":_vm.handleClickUpdate}},[_c('v-icon',{staticClass:"mr-1",attrs:{"small":""}},[_vm._v("mdi-square-edit-outline")]),_vm._v(" modificar ")],1),_c('v-btn',{attrs:{"small":"","depressed":""},on:{"click":function($event){_vm.showChangePassword = true}}},[_c('v-icon',{staticClass:"mr-1",attrs:{"small":""}},[_vm._v("mdi-lock")]),_c('span',{staticClass:"d-none d-sm-inline"},[_vm._v("cambiar contraseña")]),_c('span',{staticClass:"d-inline d-sm-none"},[_vm._v("contraseña")])],1)],1)],1),_c('v-dialog',{attrs:{"width":"800","persistent":""},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"]))return null;_vm.showForm = false}},model:{value:(_vm.showForm),callback:function ($$v) {_vm.showForm=$$v},expression:"showForm"}},[_c('v-card',{staticClass:"pa-sm-10 pa-5"},[_c('div',{staticClass:"text-right"},[_c('v-icon',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){_vm.showForm = false}}},[_vm._v(" mdi-close ")])],1),_c('DoctorForm',{ref:"DoctorForm",on:{"updated":_vm.handleUpdated}})],1)],1),_c('v-dialog',{attrs:{"width":"600","persistent":""},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"]))return null;_vm.showChangePassword = false}},model:{value:(_vm.showChangePassword),callback:function ($$v) {_vm.showChangePassword=$$v},expression:"showChangePassword"}},[_c('v-card',{staticClass:"pa-sm-10 pa-5"},[_c('div',{staticClass:"text-right"},[_c('v-icon',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){_vm.showChangePassword = false}}},[_vm._v(" mdi-close ")])],1),_c('ChangePassword',{on:{"updated":function($event){_vm.showChangePassword = false}}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }