<template>
  <div class="text-center text-md-start">
    <h1 class="headline">
      {{ currentUser.firstName }} {{ currentUser.lastName }}
    </h1>
    <p class="font-weight-medium my-8">
      <v-icon>mdi-email</v-icon>
      Correo electrónico:
      <span class="dark_primary--text" v-if="currentUser.email">{{
        currentUser.email
      }}</span>
      <span class="light_secondary--text" v-else>Sin definir</span>
    </p>
    <p class="font-weight-medium my-8">
      <v-icon>mdi-phone</v-icon>
      Teléfono:
      <span class="dark_primary--text" v-if="currentUser.phone">{{
        currentUser.phone
      }}</span>
      <span class="light_secondary--text" v-else>Sin definir</span>
    </p>
    <v-row justify="space-between" class="mt-16">
      <v-col>
        <v-btn small depressed dark @click="showChangePassword = true">
          <v-icon small class="mr-2">mdi-lock</v-icon>
          <span class="d-none d-sm-inline">cambiar contraseña</span>
          <span class="d-inline d-sm-none">contraseña</span>
        </v-btn>
      </v-col>
    </v-row>

    <v-dialog
      width="600"
      v-model="showChangePassword"
      @keydown.esc="showChangePassword = false"
      persistent
    >
      <v-card class="pa-sm-10 pa-5">
        <div class="text-right">
          <v-icon @click="showChangePassword = false" style="cursor: pointer">
            mdi-close
          </v-icon>
        </div>
        <ChangePassword @updated="showChangePassword = false" />
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ChangePassword from "@/components/Profile/ChangePassword";
import AdminForm from "@/components/Admin/AdminForm";

export default {
  name: "Admin",
  components: {
    ChangePassword,
    AdminForm,
  },
  data() {
    return {
      showForm: false,
      showChangePassword: false,
    };
  },
  computed: {
    ...mapGetters(["currentUser"]),
  },
};
</script>
