<template>
  <v-container class="pa-md-5" fluid>
    <v-row>
      <v-col>
        <h2 class="secondary--text">Mi cuenta</h2>
        <v-divider class="mb-8 mt-2"></v-divider>
        <v-col v-if="currentUser">
          <Doctor v-if="currentUser.role === 'doctor'" />
          <Secretary v-if="currentUser.role === 'secretary'" />
          <Admin v-if="currentUser.role === 'admin'" />
        </v-col>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Doctor from "@/components/Profile/Doctor";
import Admin from "@/components/Profile/Admin";
import { mapGetters } from "vuex";
import Secretary from "../components/Profile/Secretary.vue";

export default {
  name: "Profile",
  components: {
    Doctor,
    Admin,
    Secretary,
  },
  computed: {
    ...mapGetters(["currentUser"]),
  },
};
</script>
